



































import { defineComponent, PropType } from "@vue/composition-api";
import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

interface IHeader {
  value: "id" | "fio";
  defaultWidth: number;
  isSorted: boolean;
  isHandle: boolean;
  id: string;
}

export default defineComponent({
  props: {
    maxUserLength: {
      type: Number,
    },
    tableHeaders: {
      type: Array as PropType<IHeader[]>,
      default() {
        return [];
      },
    },
    userList: {
      type: Array as PropType<{ id: number; fio: string }[]>,
      default() {
        return [];
      },
    },
    selectedUsers: {
      type: Array as PropType<{ id: number; fio: string }[]>,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      headerCheckboxValue,
      selectedIdsElements: {} as Record<string, boolean>,
    };
  },
  created() {
    this.selectedIdsElements = this.selectedUsers.reduce((res: Record<string, boolean>, item) => {
      res[item.id.toString()] = true;
      return res;
    }, {});
  },
  computed: {
    selectedIdsLength(): number {
      return Object.values(this.selectedIdsElements).map((item) => !!item).length;
    },
  },
  methods: {
    clickCheckbox(id: number) {
      this.selectedIdsElements = {
        ...this.selectedIdsElements,
        [id]: !this.selectedIdsElements[id],
      };

      if (this.maxUserLength) {
        let count = 0;
        let ids: number[] = [];
        this.selectedIdsElements = Object.fromEntries(
          Object.entries(this.selectedIdsElements).map(([key, value]) => {
            if (+key !== id && value && count + 1 >= (this.maxUserLength ?? 0)) {
              ids = ids.concat(+key);
              return [key, false];
            } else {
              count += 1;
              return [key, value];
            }
          })
        );
        this.$emit("unselectUsers", ids);
      }

      if (this.selectedIdsElements[id]) {
        this.$emit(
          "selectUser",
          this.userList.find((item) => item.id === id)
        );
      } else {
        this.$emit("unselectUser", id);
      }
    },
  },
});
