















































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { v4 as uuid } from "uuid";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { defineComponent, PropType } from "@vue/composition-api";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import UserTable from "./UserTable.vue";
import { mapActions } from "vuex";
import { debounce } from "lodash";
import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";

export default defineComponent({
  name: "CommisionUserModal",
  components: {
    TableHeader,
    InfiniteScroll,
    UserTable,
  },
  props: {
    approveModal: {
      type: Function as PropType<(selectedUsers: { id: number; fio: string }[]) => void>,
      default: () => 1,
    },
    maxUserLength: {
      type: Number,
    },
    title: {
      type: String,
      default() {
        return "Добавление согласующих";
      },
    },
    subTitle: {
      default() {
        return "Вы можете добавить несколько согласующих из списка";
      },
    },
    type: {
      type: String as PropType<"APPROVING" | "COORDINATING">,
      default() {
        return "APPROVING";
      },
    },
    listUsers: {
      type: Array as PropType<{ id: number; fio: string }[]>,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      search: "",
      cells: [],
      userList: [] as { id: number; fio: string }[],
      selectedUsers: [] as { id: number; fio: string }[],
      headers: [
        headerCheckboxObject(),
        {
          text: "Фамилия Имя Отчество",
          tooltip: "",
          value: "fio",
          id: uuid(),
        },
      ],
      isSelectAllElement: false,
      infiniteId: Date.now().toString(),
    };
  },
  created() {
    this.selectedUsers = this.listUsers;
  },
  methods: {
    ...mapActions("ExpertComissionView", ["getUsersList"]),
    searchDebounce: debounce(function (this: { infiniteId: string; userList: { id: number; fio: string }[] }) {
      this.infiniteId = Date.now().toString();
      this.userList = [];
    }, 500),
    handleUnselectUsers(ids: number[]) {
      this.selectedUsers = this.selectedUsers.filter((user) => !ids.includes(user.id));
    },
    handleSelectUser(user: { id: number; fio: string }) {
      this.selectedUsers = this.selectedUsers.concat(user);
    },
    handleUnselectUser(id: number) {
      this.selectedUsers = this.selectedUsers.filter((user) => user.id !== id);
    },
    handleApproveModal() {
      this.approveModal?.(this.selectedUsers);
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },

    async getEventList() {
      const params = {
        authority: this.type === "APPROVING" ? 104 : 103,
        ...(this.search ? { fullname: this.search } : {}),
        offset: this.userList.length,
        limit: 50,
      };
      const { data, totalLength }: { data: IUser[]; totalLength: number } = await this.getUsersList(params);
      const usersResult: { id: number; fio: string }[] = data.map((item) => {
        return {
          id: +item.id,
          fio: item.fullName ?? "",
        };
      });
      this.userList = this.userList.concat(usersResult);
      return { data: this.userList, totalLength };
    },
  },
});
